const triggers = document.querySelectorAll('.js-trigger');
const modals = document.querySelectorAll('.js-modal');
const overlays = document.querySelectorAll('.js-modal-overlay');
const iframes = document.querySelectorAll('.js-modal-iframe');
const closeButtons = document.querySelectorAll('.js-close-btn');

triggers.forEach((trigger, index) => {
  const overlay = overlays[index];
  const modal = modals[index];
  const closeButton = closeButtons[index];

  const toggleModal = () => {
    // show or hide the modal
    modal.classList.toggle('hidden');
    modal.classList.toggle('opacity-100');
    modal.classList.toggle('opacity-0');

    // show or hide the overlay
    overlay.classList.toggle('opacity-100');
    overlay.classList.toggle('opacity-0');
    overlay.classList.toggle('pointer-events-none');

    // lock or unlock the body from scrolling
    const scrollPosition = window.pageYOffset;
    document.body.classList.toggle('overflow-hidden');
    document.body.style.top = document.body.classList.contains('overflow-hidden')
      ? `-${scrollPosition}px`
      : '';

    // start or pause the video
    const iframe = iframes[index];
    if (modal.classList.contains('opacity-100')) {
      iframe.setAttribute('src', iframe.dataset.src);
    } else {
      iframe.setAttribute('src', '');
    }
  };

  trigger.addEventListener('click', toggleModal);
  overlay.addEventListener('click', toggleModal);
  closeButton.addEventListener('click', toggleModal);
});
