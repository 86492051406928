(function () {
  const dropdownToggleButtons = document.querySelectorAll('.js-dropdown-toggle');

  dropdownToggleButtons.forEach((button) => {
    button.addEventListener('click', () => {
      const dropdownMenu = button.nextElementSibling;
      const ariaExpanded = dropdownMenu.classList.contains('hidden') ? 'false' : 'true';

      dropdownMenu.classList.toggle('hidden');
      button.setAttribute('aria-expanded', ariaExpanded);

      const ariaLabel = dropdownMenu.classList.contains('hidden')
        ? 'Open submenu'
        : 'Close submenu';
      button.setAttribute('aria-label', ariaLabel);
    });
  });

  document.body.addEventListener('click', (event) => {
    const targetElement = event.target;

    dropdownToggleButtons.forEach((button) => {
      const dropdownMenu = button.nextElementSibling;

      if (!dropdownMenu.contains(targetElement) && button !== targetElement) {
        dropdownMenu.classList.add('hidden');
      }
    });
  });
})();
