export function initHamburger() {
  const hamburgerButton = document.querySelector('.js-hamburger');
  const nav = document.querySelector('#mobile-menu');

  hamburgerButton.addEventListener('click', (e) => {
    e.preventDefault();
    e.stopPropagation();
    nav.classList.toggle('hidden');
    hamburgerButton.classList.toggle('open');
  });
}
